import React, { Children, useContext } from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Button,
    FormControl, FormLabel, Select, Textarea, ChakraProvider, Box,
    Input
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik';
import { useInterview } from '../../../context/InterviewContext';

const InterviewSchedule = ({ children, applicationID, roundno }) => {
    // const { recordCvRes } = usePipeLine()
    const { scheduleInterview } = useInterview()
    return (
        <Popover>
            <PopoverTrigger>
                <span>{children}</span>
            </PopoverTrigger>
            <PopoverContent color='black'>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Schedule Interview</PopoverHeader>
                <PopoverBody>
                    <ChakraProvider>
                        <Box maxW="md" mx="auto" mt="10">
                            <Formik
                                initialValues={{ scheduledOn: '' }}
                                onSubmit={(values) => {
                                    console.log("values", values.scheduledOn, "applicationID", applicationID, "roundno", roundno)
                                    const postData = {
                                        roundNo: roundno,
                                        scheduledOn: values.scheduledOn,

                                    }
                                    scheduleInterview(applicationID, postData)
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>


                                        <Field name="scheduledOn">
                                            {({ field }) => (
                                                <FormControl id="scheduledOn" mb="4">
                                                    <FormLabel>Scheduled On</FormLabel>
                                                    <Input {...field} type="datetime-local" placeholder="Select date and time" />
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Button type="submit" colorScheme="blue">Submit</Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </ChakraProvider>

                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default InterviewSchedule