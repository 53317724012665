import React, { Fragment } from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import Header from "../../admin/components/Header";
import { useAuth } from "../../context/AuthContext";
import AdminSubheader from "../../admin/components/AdminSubheader";

export const Placements = () => {
  const { userData } = useAuth();

  const data = [
    {
      PlacementsID: "gfvgvdcv",
      CandidateName: "bchbh",
      PipelineID: "gagvv",
      RequirementID: "bshcxcx",
      AccountName: "hgbdvcv",
      InvoiceNo: "hbasdvgt",
      CreatedDate: "20-10-2076",
    },
    {
      PlacementsID: "ddvfsvg ",
      CandidateName: "dbdchn ",
      PipelineID: "bhbc",
      RequirementID: "bnhxbx",
      AccountName: "bachs",
      InvoiceNo: "asbdhsas",
      CreatedDate: "30-10-1976",
    },
    {
      PlacementsID: "hbhdc",
      CandidateName: " dgssvhv",
      PipelineID: "bshxx ",
      RequirementID: "bshvvsdb",
      AccountName: "asvhss",
      InvoiceNo: "asffcasfc",
      CreatedDate: "20-15-2009",
    },
  ];
  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Placements
            </h1>
            {/* <div className={`bg-blue-500 h-[30px] w-[120px] rounded-[10px] shadow-lg border-[1px] shadow-blue-500 text-white  text-center mt-4 text-[16px]`}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="placement"
                    sheet="tablexls"
                    buttonText="Export Excel" />
            </div> */}
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-4 h-[500px] bg-blue-900  shadow-lg shadow-blue-500  items-center overflow-hidden ${styles.boxWidth}`}
            >
              <table
                className="table-auto w-full text-center z-[1]"
                id="table-to-xls"
              >
                <thead className="border-b">
                  <tr>
                    <th>Placements ID</th>
                    <th>Candidate Name</th>
                    <th>Pipeline ID</th>
                    <th>Requirement ID</th>
                    <th>Account Name</th>
                    <th>Invoice No.</th>
                    <th>Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr>
                      <td>{item.PlacementsID}</td>
                      <td>{item.CandidateName}</td>
                      <td>{item.PipelineID}</td>
                      <td>{item.RequirementID}</td>
                      <td>{item.AccountName}</td>
                      <td>{item.InvoiceNo}</td>
                      <td>{item.CreatedDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
