import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import { sortTransactionsByDate } from "../Utils/ExtraFun";

const CandidateContext = createContext();

export const CandidateContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const [allCandidates, setAllCandidates] = useState([]);
  const [candidateData, setCandidateData] = useState({});

  // useEffect(() => {
  //   if (userData) console.log("userDatacreateContext", userData.token)
  // }, [userData])

  const addCandidate = async (values) => {
    console.log("addCandidateValues", values);
    let loadingToast;
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      loadingToast = toast.loading("Adding Candidate ...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });

      await axios
        .post(`${backendUrl}/candidate/add`, values, { headers: headers })
        .then((res) => {
          console.log("res.data.message", res.data);
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      else
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAllCandidate = async () => {
    let loadingToast;
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      loadingToast = toast.loading("Fetching all Candidate ...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });

      await axios
        .get(`${backendUrl}/candidate/all`, { headers: headers })
        .then((res) => {
          console.log("getAllCandidateRes =======", res.data);
          const data = sortTransactionsByDate(res.data.candidates);
          setAllCandidates(data);
          toast.success("Successfully fetched", { position: "top-right" });
        });
    } catch (err) {
      console.log(err);
      if (err.message)
        toast.error(`${err.message}`, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      else
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getCandidate = async (id) => {
    console.log("candidateId", id);
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/candidate?candidateId=${id}`, { headers: headers })
        .then((res) => {
          console.log(res.data.candidate);
          setCandidateData(res.data.candidate);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CandidateContext.Provider
      value={{
        addCandidate,
        getAllCandidate,
        allCandidates,
        getCandidate,
        candidateData,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export default CandidateContext;

export const useCandidate = () => useContext(CandidateContext);
