import React, { Fragment } from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import Header from "../../admin/components/Header";
import { useAuth } from "../../context/AuthContext";
import AdminSubheader from "../../admin/components/AdminSubheader";

export const FeesShare = () => {
  const { userData } = useAuth();
  const data = [
    {
      FeeShareID: "jill",
      OfferID: "smith",
      ofShare: 22,
      Amount: 5,
      CreatedDate: "java",
    },
    {
      FeeShareID: "david",
      OfferID: "warner",
      ofShare: 23,
      Amount: 7,
      CreatedDate: "c++",
    },
    {
      FeeShareID: "nick",
      OfferID: "james",
      ofShare: 26,
      Amount: 6,
      CreatedDate: "python",
    },
  ];
  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={`  bg-black overflow-hidden w-full  h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Fees Share
            </h1>
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-4 h-[500px] bg-blue-900  shadow-lg shadow-blue-500  items-center overflow-hidden ${styles.boxWidth}`}
            >
              <table
                className="table-auto w-full text-center z-[1]"
                id="table-to-xls"
              >
                <thead className="border-b">
                  <tr>
                    <th>Fee Share ID</th>
                    <th>Offer ID</th>
                    <th>% of Share</th>
                    <th>Amount</th>
                    <th>Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr>
                      <td>{item.FeeShareID}</td>
                      <td>{item.OfferID}</td>
                      <td>{item.ofShare}</td>
                      <td>{item.Amount}</td>
                      <td>{item.CreatedDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
