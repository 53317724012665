import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "../../Styles";
// import {close,menu,logo2} from "../assets"
import { logo2, close, menu } from "../../assets";
import { useAuth } from "../../context/AuthContext";
import { Button } from "@chakra-ui/react";
export const RecruimentHeader = () => {
  const { handleSignOut, userData } = useAuth();
  const [toggle, setToggle] = useState(false);
  console.log("userData -=============", userData)
  function refreshPage() {
    window.location.reload();
  }
  return (
    <div className={` bg-black  w-full h-fit `}>
      <div className={`${styles.paddingX} ${styles.flexCenter} z-[3] `}>
        <div className={`${styles.boxWidth} items-center `}>
          <nav className="w-full flex py-6 justify-between items-center navbar   ">
            <img
              src={logo2}
              alt="Daya-Consultancy"
              className="w-[150px] h-[60px] brand"
            />
            <ul className="list-none sm:flex hidden justify-end items-center flex-1 z-[4]">
              {/* <img
                width="25"
                height="25"
                src="https://img.icons8.com/office/16/administrator-male--v1.png"
                alt="administrator-male--v1"
              />
              <li class="nav-item text-white ">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  aria-current="page"
                >
                  Name
                </Link>
              </li> */}
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  to="/new_candidate"
                >
                  New Candidate
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  to="/candidate"
                >
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  onClick={refreshPage}
                >
                  Refresh
                </Link>
              </li>
              <li class="nav-item">
                <Button
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                  onClick={handleSignOut}
                >
                  Logout
                </Button>
              </li>
            </ul>

            <div className="sm:hidden flex flex-1 justify-end items-center">
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              />

              <div
                className={`${!toggle ? "hidden" : "flex"
                  } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
              >
                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                  <li class="nav-item ">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      aria-current="page"
                      to="/"
                    >
                      Name
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      to="/NewCandidate"
                    >
                      New Candidate
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      onClick={refreshPage}
                    >
                      Refresh
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Button
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      onClick={handleSignOut}
                    >
                      Logout
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* <SubHeader/> */}
        </div>
      </div>
    </div>
  );
};
