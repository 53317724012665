import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { PipelineContext } from "../../context/PipelineContext";
import { FaWhatsapp } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { CgDetailsMore } from "react-icons/cg";
import { useJd } from "../../context/JobDescriptContext";
import { SheduleInterviewModal } from "../component/modal/SheduleInterviewModal";
import AdminSubheader from "../../admin/components/AdminSubheader";
import Header from "../../admin/components/Header";

export const Interviews = () => {
  const moreDetailsRef = useRef([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const { getAllJd, allJds } = useJd();
  const { handleApplicationForm, fetchedData } = useContext(PipelineContext);
  const [filteredData, setFilteredData] = useState(fetchedData);
  const { userData } = useAuth();
  const [openIndex, setOpenIndex] = useState(null);
  // const { handleScheduleInterview } = useContext(ApplicationContext);
  useEffect(() => {
    handleApplicationForm();
  }, []);

  useEffect(() => {
    const currentState = fetchedData.filter(
      (item) => item?.cvSelectionDetails?.result === "Selected"
    );
    console.log("CV Selected============", currentState);
    setFilteredData(currentState);
    setOpenIndex(null);
    console.log("current state", currentState);
  }, [fetchedData]);

  useEffect(() => {
    if (userData.token) {
      handleApplicationForm();
      getAllJd();
    }
  }, [userData.token]);

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Interviews
            </h1>
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-4 h-[500px] bg-blue-900 shadow-lg shadow-blue-500 items-center overflow-scroll scrollbar-hidden ${styles.boxWidth}`}
            >
              <table
                className="table-auto w-full text-center z-[1]"
                id="table-to-xls"
              >
                <thead className="border-b">
                  <tr>
                    <th rowSpan="2" className="border">
                      Application ID
                    </th>
                    <th colSpan="1" className="border">
                      Job Description
                    </th>
                    <th colSpan="1" className="border">
                      Recruiter
                    </th>
                    <th colSpan="4" className="border">
                      Candidate
                    </th>
                    {userData.userType === "Recruiter" && (
                      <th rowSpan="2" className="border">
                        More
                      </th>
                    )}
                  </tr>
                  <tr>
                    <th className="border">Job ID</th>
                    <th className="border">Emp ID</th>
                    <th className="border">Student ID</th>
                    <th className="border">Email</th>
                    <th className="border">Name</th>
                    <th className="border">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.applicationID}</td>
                      <td>{item.jobDescription.jobId}</td>
                      <td>{item.recruiter.empId}</td>
                      <td>{item.candidate.stuId}</td>
                      <td>{item.candidate.email}</td>
                      <td>{item.candidate.name}</td>
                      <td className="flex justify-center items-center gap-3">
                        <a
                          href={`https://wa.me/${item.candidate.phone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center border-none"
                        >
                          <FaWhatsapp className="bg-green-500 text-lg rounded-lg border-none" />
                        </a>
                        {item.candidate.phone}
                      </td>
                      {userData.userType === "Recruiter" && (
                        <td className=" bg-black">
                          <Menu backgroundColor={"black"}>
                            <MenuButton alignItems={"center"} as={Button}>
                              <CgDetailsMore />
                            </MenuButton>

                            <MenuList>
                              <MenuItem
                                backgroundColor={"black"}
                                _hover={{ backgroundColor: "blue.500" }}
                              >
                                <SheduleInterviewModal
                                  appDetails={item}
                                  applicationID={item._id}
                                  JDID={item?.jobDescription?._id}
                                  allJDs={allJds}
                                >
                                  Schedule Interviews
                                </SheduleInterviewModal>
                              </MenuItem>
                              <MenuItem
                                backgroundColor={"black"}
                                _hover={{ backgroundColor: "blue.500" }}
                              >
                                Record Offer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
