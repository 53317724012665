import React, { useContext, useEffect, useState } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useAuth } from "../../context/AuthContext";

const EmployeeListPopup = ({ isOpen, onClose, selectedRecruiterIds, setSelectedRecruiterIds, selectedRecruiters, setSelectedRecruiters }) => {
  const { getNonDeletedData, employees } = useContext(EmployeeContext);
  const { userData } = useAuth();
  const [recruiters, setRecruiters] = useState([]);
  useEffect(() => {
    if (!userData) return;
    getNonDeletedData();
  }, [userData]);

  useEffect(() => {
    if (!employees || employees?.length === 0) return;

    const filteredData = employees.filter(
      (user) => user.userType.toLowerCase() === "recruiter"
    );
    setRecruiters(filteredData);
  }, [employees]);

  useEffect(() => {
    if (!recruiters) return;

    console.log("recruiters==========", recruiters);
  }, [recruiters]);
  const onAddRecruiter = (id) => {
    const selectedRecruiter = recruiters?.find((emp) => emp._id === id);
    if (selectedRecruiter) {
      setSelectedRecruiterIds((prevIds) => [...prevIds, id]);
      setSelectedRecruiters((prevRecruiters) => [...prevRecruiters, selectedRecruiter]);
    }
  };

  const onRemoveRecruiter = (id) => {
    setSelectedRecruiterIds((prevIds) => prevIds.filter((recruiterId) => recruiterId !== id));
    setSelectedRecruiters((prevRecruiters) =>
      prevRecruiters.filter((recruiter) => recruiter._id !== id)
    );
  };
  useEffect(() => {
    console.log(selectedRecruiterIds)
  }, [selectedRecruiterIds])
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-4 rounded-lg shadow-lg w-1/2">
        <div className="flex justify-between items-center border-b pb-2 mb-4">
          <h2 className="text-xl font-bold">Select Recruiter</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-900"
          >
            &times;
          </button>
        </div>
        <ul className="space-y-2 mb-4">
          {recruiters.map((emp, index) => (
            <li
              key={index}
              className="flex justify-between   items-center p-2 border rounded-lg"
            >
              <span>
                {emp.empId} - {emp.name}
              </span>
              {selectedRecruiterIds.includes(emp._id) ? (
                <button
                  type="button"
                  onClick={() => onRemoveRecruiter(emp._id)}
                  className="bg-red-500 text-white font-semibold py-1 px-3 rounded-lg"
                >
                  Remove
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => onAddRecruiter(emp._id)}
                  className="bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                >
                  Add
                </button>
              )}
            </li>
          ))}
        </ul>
        <div >
          <h3 className="text-lg font-bold mb-2">Selected Recruiters:</h3>
          <ul className="space-y-2">
            {selectedRecruiters.map((emp, index) => (
              <li
                key={index}
                className="flex  text-red-400 justify-between items-center p-2 border rounded-lg"
              >
                <span>
                  {emp.empId} - {emp.name}
                </span>
                <button
                  type="button"
                  onClick={() => onRemoveRecruiter(emp._id)}
                  className="text-red-500 font-semibold py-1 px-3 rounded-lg"
                >
                  &times;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmployeeListPopup;
