import React, { Fragment, useContext, useEffect, useState } from "react";
import styles from "../../Styles";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import AdminSubheader from "../components/AdminSubheader";
import EditForm from "../forms/EditForm";
import { EmployeeContext } from "../../context/EmployeeContext";
import { logo2 } from "../../assets";
import { FaArrowDownShortWide, FaSquareWhatsapp } from "react-icons/fa6";
import { DeletedEmployeeContext } from "../../context/DeletedEmployee";
import { MdDelete } from "react-icons/md";
import { useAuth } from "../../context/AuthContext";

const AdminEmployee = () => {
  const { getNonDeletedData, employees } = useContext(EmployeeContext);
  const { deletedData, delUsers } = useContext(DeletedEmployeeContext);
  const { userData } = useAuth();

  useEffect(() => {
    if (!userData) return;
    getNonDeletedData();
  }, [userData]);

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filteredEmps, setFilteredEmps] = useState([]);

  useEffect(() => {
    if (userData?.userType === "Business Developer" || userData?.userType === "HR Manager") {

      setIsShowFilter(false);
      const filteredData = employees.filter(
        (user) => user.userType.toLowerCase() === "recruiter"
      );
      setFilteredEmps(filteredData);
    } else {
      setFilteredEmps(employees);
    }
  }, [employees, userData]);

  const handleFilterClick = () => {
    setIsShowFilter(!isShowFilter);
  };

  const handleSelectChange = async (e) => {
    const value = e.target.value;
    if (value === "all-employee") {
      getNonDeletedData();
    } else if (value === "deleted-employee") {
      deletedData();
    }
  };

  const filterData = (value) => {
    if (value.toLowerCase() === "all") {
      setFilteredEmps(employees);
    } else {
      const filteredData = employees.filter(
        (user) => user.userType.toLowerCase() === value.toLowerCase()
      );
      setFilteredEmps(filteredData);
    }
  };

  const handleRoleFilterChange = (e) => {
    const value = e.target.value;
    filterData(value);
  };

  const deletedUser = () => {
    console.log("deleted button is clicked");
  };

  return (
    <Fragment>
      <Header />
      <AdminSubheader />

      <div
        className={` bg-black overflow-scroll  w-full max-h-fit h-[80%] pipeline `}
      >
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div
            className={`${styles.boxWidth} items-center justify-items-center`}
          >
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Employees
            </h1>
            {
              userData.userType === 'Admin' &&
              <div
                className={`mt-[25px] font-poppins font-normal cursor-pointer text-center flex flex-row justify-around `}
              >
                <select
                  name="candidate-list"
                  id="candidate-list"
                  className={`text-center float-left justify-center border-2 w-48 h-fit bg-blue-900 text-white shadow-lg shadow-blue-500 `}
                  onChange={handleSelectChange}
                >
                  <option value="all-employee" className={`text-white`}>
                    All Employee
                  </option>
                  <option value="deleted-employee">Deleted Employee</option>
                </select>
              </div>
            }

            <div className="flex h-[60px] w-[full] justify-center border-2 relative bg-blue-900 shadow-lg shadow-blue-500 mt-[60px] ">
              <div className="input-group mx-2 items-center gap-12 flex flex-1 w-[400px] mb-4 space-x-1.5">
                {userData?.userType !== "Business Developer" && (
                  <FaArrowDownShortWide
                    onClick={handleFilterClick}
                    className="cursor-pointer w-16 h-10"
                  />
                )}
                {isShowFilter && (
                  <div className="bg-white rounded-lg absolute top-16 shadow-lg w-48 h-10">
                    <select
                      onChange={handleRoleFilterChange}
                      className="w-full rounded-lg font-xl font-serif text-center bg-white text-black h-full"
                    >
                      <option value="all">All</option>
                      <option value="admin">Admin</option>
                      <option value="Recruiter">Recruiter</option>
                      <option value="Business Developer">
                        Business Developer
                      </option>
                      <option value="HR Manager">HR Manager</option>
                    </select>
                  </div>
                )}

                <input
                  type="text"
                  className="form-control relative ml-10 flex min-w-0 mt-[10px] w-[70%] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon3"
                />

                <button
                  className="mt-2 btn px-5 py-2 border-2 border-white text-white font-poppins text-xs uppercase rounded-xl hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  type="button"
                  id="button-addon3"
                >
                  Search
                </button>
              </div>
            </div>

            <div className="text-white font-poppins text-[16px] overflow-scroll border-2 mt-5 h-[50rem] max-h-fit bg-blue-900 shadow-lg shadow-blue-500 items-center">
              {filteredEmps.length > 0 ? (
                <table
                  className="table-auto w-full text-center z-[1]"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b-2 border-gray-300">
                        Employee ID
                      </th>
                      <th className="py-2 px-4 border-b-2 border-gray-300">
                        Name
                      </th>
                      <th className="py-2 px-4 border-b-2 border-gray-300">
                        Email
                      </th>
                      <th className="py-2 px-4 border-b-2 border-gray-300">
                        Phone Number
                      </th>
                      <th className="py-2 px-4 border-b-2 border-gray-300">
                        User Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEmps.map((user) => (
                      <tr key={user.empId}>
                        <td className="py-2 px-4 border-none border-gray-200">
                          {user.empId}
                        </td>
                        <td className="py-2 px-4 border-none border-b flex gap-2 border-gray-200">
                          <img
                            className="w-10 bg-red-400 h-10 rounded-full object-fill"
                            src={user.dp || logo2}
                            alt=""
                          />
                          {user.name}
                        </td>
                        <td className="py-2 px-4 border-none border-gray-200">
                          {user.email}
                        </td>
                        <td className="py-2 px-4 border-none flex gap-2 border-gray-200">
                          {user?.whatsAppNo && (
                            <a
                              href={`https://wa.me/${user.countryCode}${user.whatsAppNo}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              <FaSquareWhatsapp className="w-10 h-10 text-green-500" />
                            </a>
                          )}

                          {user?.phoneNo ? (
                            <>
                              <p> {user.countryCode}</p>
                              {user?.phoneNo}
                            </>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td className="py-2 px-4 border-none border-gray-200">
                          {user.userType}
                        </td>
                        {
                          userData.userType === 'Admin' &&

                          <td className="py-2 px-4 border-none border-gray-200">
                            <MdDelete
                              onClick={deletedUser}
                              className="w-5 h-5 cursor-pointer"
                            />
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center py-5">No data available</p>
              )}
            </div>
          </div>
        </div>

        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};

export default AdminEmployee;
