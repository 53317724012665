import React, { useContext, useState } from "react";
import { createContext } from "react";
import axios from "axios";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";

//create the context
export const EmployeeContext = createContext();

//create the context provider
const EmployeeContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const getNonDeletedData = async (values) => {
    const token = userData.token;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    try {
      loadingToast = toast.loading("Fetching Data....", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      const res = await axios.get(`${backendUrl}/user/non_deleted_user`, {
        headers,
      });

      const data = res.data.users;
      toast.success(res.data.message, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      setUsers(data); // set users state
      setEmployees(data);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      setUsers([]); // set empty array if error comes
      setEmployees([]);
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <EmployeeContext.Provider value={{ getNonDeletedData, users, employees }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextProvider;
