

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Button,
    ListItem,
    Box,
} from '@chakra-ui/react'
import React from 'react'
import { useJd } from '../../../context/JobDescriptContext'

function JdDetails({ children }) {
    const { jdData } = useJd();
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <span onClick={onOpen}>{children}</span>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                size={'xl'}
                scrollBehavior={'inside'}

            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> Job Description Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text><strong>jobId:</strong> {jdData.jobId}</Text>
                        <Text> <strong>jobTitle:</strong> {jdData.jobTitle}</Text>
                        <Text> <strong>similarTags:</strong></Text>
                        {jdData?.similarTags?.map((similarTags, index) => (
                            <ul key={index}>
                                <li>{similarTags}</li></ul>
                        ))}
                        <Text> <strong>skills:</strong></Text>
                        {jdData?.skills?.map((skills, index) => (
                            <li key={index}>{skills}</li>
                        ))}
                        <Text> <strong>Description:</strong>{jdData.description}</Text>
                        <Text> <strong>experience:</strong>{jdData.experience}</Text>
                        <Text> <strong>relevantExperience:</strong>{jdData.relevantExperience}</Text>
                        <Text> <strong>numberOfOpenings:</strong>{jdData.numberOfOpenings}</Text>
                        <Text> <strong>interviewRounds:</strong></Text>
                        {jdData?.interviewRounds?.map((interviewRounds, index) => (
                            <ul key={index}>
                                <li>{interviewRounds?.roundNo}</li>
                                <li>{interviewRounds?.title}</li>
                                <li>{interviewRounds?.instructions}</li>
                            </ul>
                        ))}
                        <Text> <strong>closingDate:</strong>{jdData.closingDate}</Text>

                        <Text> <strong>salaryOffering:</strong>{jdData.salaryOffering}</Text>
                        <Text> <strong>noOfClosings:</strong>{jdData.noOfClosings}</Text>
                        <Text> <strong>percentage:</strong>{jdData.percentage}</Text>
                        <Text> <strong>workingRecruiters:</strong></Text>
                        {jdData?.workingRecruiters?.map((workingRecruiters, index) => (
                            <Box key={index}>
                                <Text>{index + 1} .</Text>
                                <Text> <strong>empId :</strong>{workingRecruiters.empId}</Text>
                                <Text> <strong>Email :</strong>{workingRecruiters.email}</Text>
                                <Text> <strong>name :</strong>{workingRecruiters.name}</Text>
                                <Text> <strong>phoneNo :</strong>{workingRecruiters.phoneNo}</Text>
                                <Text> <strong>userType :</strong>{workingRecruiters.userType}</Text>
                            </Box>
                        ))}

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* <Text>companyDetails:
                            {jdData?.companyDetails.map((companyDetails, index) => (
                                <ul key={index}>
                                    <li>{companyDetails.companyName}</li>
                                    <li>{companyDetails.companyField}</li>
                                </ul>
                            ))}</Text> */}
        </>
    )
}

export default JdDetails