import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import styles from "../../Styles";
import { RecruiterNewHeader } from "../component/RecruiterNewHeader";
import { useCandidate } from "../../context/CandidateContext";
import axios from "axios";
import toast from "react-hot-toast";
import { handleKeyDown } from "../../Utils/ExtraFun";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phnum: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
});

export const NewCandidateForm = () => {
  const { addCandidate } = useCandidate();
  const [cv, setCv] = useState(null);
  const [dp, setDp] = useState(null);
  const [cvPreview, setCvPreview] = useState(null);

  const initialValues = {
    stuId: "",
    name: "",
    email: "",
    phone: "",
    whatsappNo: "",
    skills: [],
    isFresher: "",
    hasInternship: "",
    yearsOfExperience: "",
    experienceType: "",
    previousCompany: {
      companyName: '',
      companyAddress: {
        city: '',
        state: '',
        country: '',
      },
    },
    stipend: "",
    currentCTC: "",
    expectedCTC: "",
    noticePeriod: "",
    address: {
      at: "",
      po: "",
      city: "",
      dist: "",
      state: "",
      country: "",
      pin: "",
    },
  };

  const handleCvChange = (event) => {
    const file = event.target.files[0];
    setCv(file);
    setCvPreview(URL.createObjectURL(file));
  };

  const handleDpChange = (event) => {
    setDp(event.target.files[0]);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("cv", cv);
    formData.append("dp", dp);
    values.skills.forEach((value, index) => {
      formData.append(`skills[${index}]`, value);
    });
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("whatsappNo", values.whatsappNo);
    formData.append("isFresher", values.isFresher);
    formData.append("hasInternship", values.hasInternship);
    formData.append("experience", values.yearsOfExperience);
    formData.append("experienceType", values.experienceType);


    formData.append("stipend", values.stipend);
    formData.append("currentCTC", values.currentCTC);
    formData.append("expectedCTC", values.expectedCTC);
    formData.append("noticePeriod", values.noticePeriod);
    Object.keys(values.address).forEach((key) => {
      formData.append(`address[${key}]`, values.address[key]);
    });

    Object.keys(values.previousCompany).forEach((key) => {
      if (typeof values.previousCompany[key] === 'object') {
        Object.keys(values.previousCompany[key]).forEach((subKey) => {
          formData.append(`previousCompany[${key}][${subKey}]`, values.previousCompany[key][subKey]);
        });
      } else {
        formData.append(`previousCompany[${key}]`, values.previousCompany[key]);
      }
    });

    formData.forEach((value, key) => {
      console.log("key=========", key, value);
    });
    console.log("values=========", values);
    await addCandidate(formData);
    resetForm();
    setCv(null);
    setCvPreview(null);
    setDp(null);
  };

  return (
    <>
      <RecruiterNewHeader />
      <div className="bg-black overflow-hidden w-full h-[fit] pipeline">
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div
            className={`${styles.boxWidth} items-center justify-items-center`}
          >
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              New Candidate Details
            </h1>
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-10 flex ${cvPreview ? "flex-row" : "flex-col"
                } relative justify-between bg-blue-900 shadow-lg shadow-blue-500 items-center overflow-hidden ${styles.boxWidth
                }`}
            >
              <div className={`w-full ${cvPreview ? "w-[60%]" : "w-full"}`}>
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form className="Login-form" onKeyDown={handleKeyDown}>
                      <div className={"flex justify-evenly w-[80%]"}>
                        <div className={"flex flex-col"}>
                          <label>
                            Name
                            <Field
                              type="text"
                              name="name"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-red-600"
                            />
                          </label>

                          <label>
                            Email
                            <Field

                              name="email"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                        </div>
                        <div className={"flex flex-col"}>
                          <label>
                            Phone
                            <Field
                              type="text"
                              name="phone"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-red-600"
                            />
                          </label>

                          <label>
                            WhatsApp No
                            <Field
                              type="text"
                              name="whatsappNo"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="whatsappNo"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-semibold">
                          Skills
                        </label>
                        <FieldArray name="skills">
                          {({ push, remove, form }) => (
                            <div>
                              {form.values.skills?.map((skill, index) => (
                                <div
                                  key={index}
                                  className="flex items-center space-x-2"
                                >
                                  <Field
                                    name={`skills[${index}]`}
                                    className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                  />
                                  {index >= 0 && (
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      className="text-red-500"
                                    >
                                      X
                                    </button>
                                  )}
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={() => push("")}
                                className="mt-2 bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                              >
                                Add Skill
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <div>
                        <label className="block mt-5">
                          CV Upload
                          <input
                            type="file"
                            name="cv"
                            onChange={handleCvChange}
                            className="block w-full mt-1"
                          />
                        </label>

                        <label className="block mt-5">
                          Display Picture Upload
                          <input
                            type="file"
                            name="dp"
                            onChange={handleDpChange}
                            className="block w-full mt-1"
                          />
                        </label>
                      </div>

                      <div
                        className={
                          "flex w-[60%]  flex-col justify-evenly items-center"
                        }
                      >
                        <label> he/she is a fresher?</label>
                        <div className="flex flex-row items-center">
                          <label className="mr-4 flex flex-row items-center">
                            <Field type="radio" name="isFresher" value="yes" />
                            Yes
                          </label>
                          <label className="mr-4 flex flex-row items-center">
                            <Field type="radio" name="isFresher" value="no" />
                            No
                          </label>
                        </div>
                        <ErrorMessage
                          name="isFresher"
                          component="div"
                          className="text-red-600"
                        />

                        {values.isFresher === "yes" && (
                          <div
                            className={
                              "flex w-[60%]  flex-col justify-evenly items-center "
                            }
                          >
                            <label>he/she done any internship?</label>
                            <div className="flex flex-row items-center">
                              <label className="mr-4 flex flex-row items-center">
                                <Field
                                  type="radio"
                                  name="hasInternship"
                                  value="yes"
                                />
                                Yes
                              </label>
                              <label className="mr-4 flex flex-row items-center">
                                <Field
                                  type="radio"
                                  name="hasInternship"
                                  value="no"
                                />
                                No
                              </label>
                            </div>
                            {values.hasInternship === "yes" && (
                              <div className="w-full mt-2">
                                <label>
                                  Stipend
                                  <Field
                                    type="number"
                                    name="stipend"
                                    className="block w-full mt-1"
                                  />
                                  <ErrorMessage
                                    name="stipend"
                                    component="div"
                                    className="text-red-600"
                                  />
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                        <label className="block mt-10">
                          <span className="text-white font-poppins text-[16px]">
                            Experience Type
                          </span>
                          <Field
                            as="select"
                            name="experienceType"
                            className={`block w-full mt-1 text-white font-poppins text-[16px] border-2 bg-blue-900 shadow-lg shadow-blue-500 items-center overflow-hidden ${styles.boxWidth}`}
                          >
                            <option value="" className="text-white">
                              Select an option
                            </option>
                            <option value="Internship" className="text-white">
                              Internship
                            </option>
                            <option value="Full Time" className="text-white">
                              Full Time
                            </option>
                            <option value="Part Time" className="text-white">
                              Part Time
                            </option>
                            <option value="Contract" className="text-white">
                              Contract
                            </option>
                            <option value="NA" className="text-white">
                              NA
                            </option>
                          </Field>
                          <ErrorMessage
                            name="experienceType"
                            component="div"
                            className="text-red-600 mt-2"
                          />
                        </label>

                        {values.isFresher === "no" && (
                          <div className={"flex justify-evenly w-[80%] mt-4"}>
                            <div className={"flex flex-col"}>
                              <label>
                                Years of Experience
                                <Field
                                  type="text"
                                  name="yearsOfExperience"
                                  className="block w-full mt-1"
                                />
                                <ErrorMessage
                                  name="yearsOfExperience"
                                  component="div"
                                  className="text-red-600"
                                />
                              </label>
                              <div className="flex flex-col">
                                <label>
                                  Company Name
                                  <Field
                                    type="text"
                                    name="previousCompany.companyName"
                                    className="block w-full mt-1 p-2 rounded"
                                  />
                                  <ErrorMessage
                                    name="previousCompany.companyName"
                                    component="div"
                                    className="text-red-600"
                                  />
                                </label>
                              </div>
                              <div className="flex flex-col">
                                <label>
                                  City
                                  <Field
                                    type="text"
                                    name="previousCompany.companyAddress.city"
                                    className="block w-full mt-1 p-2 rounded"
                                  />
                                  <ErrorMessage
                                    name="previousCompany.companyAddress.city"
                                    component="div"
                                    className="text-red-600"
                                  />
                                </label>
                              </div>
                              <div className="flex flex-col">
                                <label>
                                  State
                                  <Field
                                    type="text"
                                    name="previousCompany.companyAddress.state"
                                    className="block w-full mt-1 p-2 rounded"
                                  />
                                  <ErrorMessage
                                    name="previousCompany.companyAddress.state"
                                    component="div"
                                    className="text-red-600"
                                  />
                                </label>
                              </div>
                              <div className="flex flex-col">
                                <label>
                                  Country
                                  <Field
                                    type="text"
                                    name="previousCompany.companyAddress.country"
                                    className="block w-full mt-1 p-2 rounded"
                                  />
                                  <ErrorMessage
                                    name="previousCompany.companyAddress.country"
                                    component="div"
                                    className="text-red-600"
                                  />
                                </label>
                              </div>

                              {/* <label>
                                Previous Company
                                <Field
                                  type="text"
                                  name="previousCompany"
                                  className="block w-full mt-1"
                                />
                                <ErrorMessage
                                  name="previousCompany"
                                  component="div"
                                  className="text-red-600"
                                />
                              </label> */}
                            </div>

                            <div className={"flex flex-col"}>
                              <label>
                                Current CTC
                                <Field
                                  type="number"
                                  name="currentCTC"
                                  className="block w-full mt-1"
                                />
                                <ErrorMessage
                                  name="currentCTC"
                                  component="div"
                                  className="text-red-600"
                                />
                              </label>

                              <label>
                                Expected CTC
                                <Field
                                  type="number"
                                  name="expectedCTC"
                                  className="block w-full mt-1"
                                />
                                <ErrorMessage
                                  name="expectedCTC"
                                  component="div"
                                  className="text-red-600"
                                />
                              </label>

                              <label>
                                Notice Period
                                <Field
                                  type="text"
                                  name="noticePeriod"
                                  className="block w-full mt-1"
                                />
                                <ErrorMessage
                                  name="noticePeriod"
                                  component="div"
                                  className="text-red-600"
                                />
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label>
                          Company Name
                          <Field
                            type="text"
                            name="previousCompany.companyName"
                            className="block w-full mt-1 bg-gray-800 p-2 rounded"
                          />
                          <ErrorMessage
                            name="previousCompany.companyName"
                            component="div"
                            className="text-red-600"
                          />
                        </label>
                      </div>
                      <div className="flex flex-col">
                        <label>
                          City
                          <Field
                            type="text"
                            name="previousCompany.companyAddress.city"
                            className="block w-full mt-1 bg-gray-800 p-2 rounded"
                          />
                          <ErrorMessage
                            name="previousCompany.companyAddress.city"
                            component="div"
                            className="text-red-600"
                          />
                        </label>
                      </div>
                      <div className="flex flex-col">
                        <label>
                          State
                          <Field
                            type="text"
                            name="previousCompany.companyAddress.state"
                            className="block w-full mt-1 bg-gray-800 p-2 rounded"
                          />
                          <ErrorMessage
                            name="previousCompany.companyAddress.state"
                            component="div"
                            className="text-red-600"
                          />
                        </label>
                      </div>
                      <div className="flex flex-col">
                        <label>
                          Country
                          <Field
                            type="text"
                            name="previousCompany.companyAddress.country"
                            className="block w-full mt-1 bg-gray-800 p-2 rounded"
                          />
                          <ErrorMessage
                            name="previousCompany.companyAddress.country"
                            component="div"
                            className="text-red-600"
                          />
                        </label>
                      </div>
                      <label className=" text-4xl">Address</label>
                      <div className="flex justify-evenly w-[80%]">
                        <div className={"flex flex-col"}>
                          <label>
                            Street
                            <Field
                              type="text"
                              name="address.at"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.at"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                          <label>
                            PO Box
                            <Field
                              type="text"
                              name="address.po"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.po"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                          <label>
                            City
                            <Field
                              type="text"
                              name="address.city"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.city"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                          <label>
                            District
                            <Field
                              type="text"
                              name="address.dist"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.dist"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                        </div>
                        <div className={"flex flex-col"}>
                          <label>
                            State
                            <Field
                              type="text"
                              name="address.state"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.state"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                          <label>
                            Country
                            <Field
                              type="text"
                              name="address.country"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.country"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                          <label>
                            Pin
                            <Field
                              type="text"
                              name="address.pin"
                              className="block w-full mt-1"
                            />
                            <ErrorMessage
                              name="address.pin"
                              component="div"
                              className="text-red-600"
                            />
                          </label>
                        </div>
                      </div>



                      <button
                        type="submit"
                        className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 rounded-lg text-xl px-4 py-2.5 text-center mr-2 mb-2 font-semibold"
                      >
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>

              {cvPreview && (
                <div className="mt-1  w-[60%] sticky top-0">
                  <h3 className="text-white text-lg">CV Preview:</h3>
                  <iframe
                    src={cvPreview}
                    width="100%"
                    height="500px"
                    className="border-2 border-white"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
