import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useAuth } from "../../context/AuthContext";

const BroughtByListPopUp = ({
  setSelectedBDIds,
  selectedBDIds,
  selectedBDs,
  setSelectedBDs,
}) => {
  const { getNonDeletedData, employees } = useContext(EmployeeContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterBD, setFilterBD] = useState(null);
  const { userData } = useAuth();

  useEffect(() => {
    if (!userData) return;
    getNonDeletedData();
  }, [userData]);

  useEffect(() => {
    if (!employees || employees?.length === 0) return;
    const filteredData = employees?.filter(
      (user) => user.userType.toLowerCase() === "business developer"
    );
    setFilterBD(filteredData);
  }, [employees]);

  //function for remove selected bussiness developer
  const onRemoveBussinessDeveloper = (id) => {
    setSelectedBDIds((prevIds) => prevIds.filter((bdId) => bdId !== id));
    setSelectedBDs((prevBds) => prevBds.filter((bd) => bd._id1 == id));
  };

  //function to add selected bussiness developer
  const onAddBussinessDeveloper = (id) => {
    const selectedBussinessDeveloper = filterBD?.find((emp) => emp._id === id);
    if (selectedBussinessDeveloper) {
      setSelectedBDIds((prevIds) => [...prevIds, id]);
      setSelectedBDs((prevBds) => [...prevBds, selectedBussinessDeveloper]);
    }
  };

  
  return (
    <div className="justify-end mt-7 flex ">
      <Button mr={'4rem'} onClick={onOpen}>
        Select Bussiness Developer
      </Button>
      <Modal  blockScrollOnMount={false}  isOpen={isOpen} onClose={onClose} >
        <ModalContent>
          <ModalBody>
            <div>
              <h3 className="text-lg font-bold  mb-2">
                Select Bussiness Devloper
              </h3>
              <ul className="space-y-2">
                {filterBD?.map((item, index) => (
                  <li
                    className="flex justify-between items-center p-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    {selectedBDIds.includes(item._id) ? (
                      <button
                        type="button"
                        className="bg-red-500 text-white font-semibold py-1 px-3 rounded-lg"
                        onClick={() => onRemoveBussinessDeveloper(item._id)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => onAddBussinessDeveloper(item._id)}
                        className="bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                      >
                        Add
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              <div>
                <h1 className="text-lg font-bold mb-2">
                  Selected Bussiness Developer:
                </h1>
                <ul className="space-y-2">
                  {selectedBDs.map((user, index) => (
                    <li
                      key={index}
                      className="flex text-red-400 justify-between items-center p-2 border rounded-lg"
                    >
                      <span>{user.name}</span>
                      <button
                        type="button"
                        onClick={() => onRemoveBussinessDeveloper(user._id)}
                        className="text-red-500 font-semibold py-1 px-3 rounded-lg"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BroughtByListPopUp;
