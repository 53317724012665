import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RecruiterNewHeader } from "../../recruiter/component/RecruiterNewHeader";
import EmployeeListPopup from "./EmployeeListPopup";
import { JobDescriptionContext } from "../../context/JobDescriptContext";
import { useAuth } from "../../context/AuthContext";
import { VStack } from "@chakra-ui/react";
import BroughtByListPopUp from "./BroughtByListPopUp";
import { handleKeyDown } from "../../Utils/ExtraFun";
import { useLocation } from "react-router-dom";

const JobDescription = ({ dontShow, jdDetails }) => {
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [showBroughtBy, setShowBroughtBy] = useState(false);
  const { handleFormSubmit } = useContext(JobDescriptionContext);

  const [initialValues, setInitialValues] = useState({
    jobId: "",
    jobTitle: "",
    similarTags: [""],
    skills: [""],
    description: "",
    experience: "",
    relevantExperience: "",
    numberOfOpenings: 0,
    interviewRounds: [
      { roundNo: 1, title: "", locationOrMode: "", instructions: "" },
    ],
    closingDate: "",
    companyDetails: { companyName: "", companyField: "" },
    salaryOffering: "",
    noOfClosings: 0,
    percentage: 0,
  });

  useEffect(() => {
    if (!jdDetails) return;
    console.log("jdDetails", jdDetails);
    setInitialValues({
      ...initialValues,
      jobTitle: jdDetails.jobTitle,
      similarTags: jdDetails.similarTags,
      skills: jdDetails.skills,
      description: jdDetails.description,
      experience: jdDetails.description,
      relevantExperience: jdDetails.relevantExperience,
      numberOfOpenings: jdDetails.numberOfOpenings,
      interviewRounds: jdDetails.interviewRounds,
      closingDate: jdDetails.closingDate,
      companyDetails: jdDetails.companyDetails,
      salaryOffering: jdDetails.salaryOffering,
      noOfClosings: jdDetails.noOfClosings,
      percentage: jdDetails.percentage,
    });
  }, [jdDetails]);
  const [selectedRecruiterIds, setSelectedRecruiterIds] = useState([]);
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [selectedBDIds, setSelectedBDIds] = useState([]);
  const [selectedBDs, setSelectedBDs] = useState([]);
  const { userData } = useAuth();

  const role = userData.userType;
  console.log(role);

  useEffect(() => {
    if (role === "Admin") {
      setShowBroughtBy(true);
    }
  }, [role, userData]);

  const formInitialValues = initialValues;

  const validationSchema = Yup.object({
    jobId: Yup.string().required("Job ID is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    description: Yup.string().required("Description is required"),
    experience: Yup.string().required("Experience is required"),
    closingDate: Yup.string().required("Closing Date is required"),
    companyDetails: Yup.object().shape({
      companyName: Yup.string().required("Company Name is required"),
      companyField: Yup.string().required("Company Field is required"),
    }),
  });

  const onSubmit = async (values) => {
    console.log("Form data", values);
    let postData;
    if (role === "Admin") {
      postData = {
        ...values,
        workingRecruiters: selectedRecruiterIds,
        broughtBy: selectedBDIds,
      };
    } else {
      postData = {
        ...values,
        workingRecruiters: selectedRecruiterIds,
        broughtBy: userData.userId,
      };
    }
    console.log(postData);
    await handleFormSubmit(postData);
    setInitialValues({
      jobTitle: "",
      similarTags: [""],
      skills: [""],
      description: "",
      experience: "",
      relevantExperience: "",
      numberOfOpenings: 0,
      interviewRounds: [
        { roundNo: 1, title: "", locationOrMode: "", instructions: "" },
      ],
      closingDate: "",
      companyDetails: { companyName: "", companyField: "" },
      salaryOffering: "",
      noOfClosings: 0,
      percentage: 0,
    });
    setSelectedRecruiterIds([]);
    setSelectedRecruiters([]);
    setSelectedBDs([]);
    setSelectedBDIds([]);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const addRecruiter = (recruiter, setFieldValue, values) => {
    setRecruiters((prevRecruiters) => [...prevRecruiters, recruiter]);
    setIsPopupOpen(false);
    const updatedRecruiters = [...values.workingRecruiters, recruiter];
    setFieldValue("workingRecruiters", updatedRecruiters);
  };
  const [name, setName] = useState("mona");
  useEffect(() => {
    if (name) console.log("vvvv", name);
  }, [name]);
  return (
    <>
      {dontShow ? " " : <RecruiterNewHeader name={name} setName={setName} />}

      <div className="bg-blue-500 h-fit py-6">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold mb-4">Job Description</h1>
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form onKeyDown={handleKeyDown}>
                <div className="flex flex-wrap -mx-4 mt-3">
                  {/* First Section */}
                  <div className="w-full lg:w-1/2 px-4 space-y-4">
                    <div>
                      <label
                        htmlFor="jobTitle"
                        className="block text-sm font-semibold"
                      >
                        Job Title
                      </label>
                      <Field
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="jobTitle"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="description"
                        className="block text-sm font-semibold"
                      >
                        Description
                      </label>
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="experience"
                        className="block text-sm font-semibold"
                      >
                        Experience
                      </label>
                      <Field
                        type="text"
                        id="experience"
                        name="experience"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="experience"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="relevantExperience"
                        className="block text-sm font-semibold"
                      >
                        Relevant Experience
                      </label>
                      <Field
                        type="text"
                        id="relevantExperience"
                        name="relevantExperience"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="relevantExperience"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="numberOfOpenings"
                        className="block text-sm font-semibold"
                      >
                        Number of Openings
                      </label>
                      <Field
                        type="number"
                        id="numberOfOpenings"
                        name="numberOfOpenings"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="numberOfOpenings"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="closingDate"
                        className="block text-sm font-semibold"
                      >
                        Closing Date
                      </label>
                      <Field
                        type="date"
                        id="closingDate"
                        name="closingDate"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="closingDate"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-semibold">
                        Similar Tags
                      </label>
                      <FieldArray name="similarTags">
                        {({ push, remove, form }) => (
                          <div>
                            {form.values.similarTags?.map((tag, index) => (
                              <div
                                key={index}
                                className="flex items-center space-x-2"
                              >
                                <Field
                                  name={`similarTags[${index}]`}
                                  className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                />
                                {index > 0 && (
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="text-red-500"
                                  >
                                    X
                                  </button>
                                )}
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => push("")}
                              className="mt-2 bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                            >
                              Add Tag
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>

                  {/* Second Section */}
                  <div className="w-full lg:w-1/2 px-4 space-y-4">
                    <div>
                      <label className="block text-sm font-semibold">
                        Skills
                      </label>
                      <FieldArray name="skills">
                        {({ push, remove, form }) => (
                          <div>
                            {form.values.skills?.map((skill, index) => (
                              <div
                                key={index}
                                className="flex items-center space-x-2"
                              >
                                <Field
                                  name={`skills[${index}]`}
                                  className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                />
                                {index >= 0 && (
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="text-red-500"
                                  >
                                    X
                                  </button>
                                )}
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => push("")}
                              className="mt-2 bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                            >
                              Add Skill
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>

                    <div>
                      <label className="block text-sm font-semibold">
                        Interview Rounds
                      </label>
                      <FieldArray name="interviewRounds">
                        {({ push, remove, form }) => (
                          <div>
                            {form.values.interviewRounds?.map(
                              (round, index) => (
                                <div
                                  key={index}
                                  className="border p-4 rounded-lg mb-4"
                                >
                                  <div>
                                    <label
                                      htmlFor={`interviewRounds[${index}].title`}
                                      className="block text-sm font-semibold"
                                    >
                                      Round Title
                                    </label>
                                    <Field
                                      type="text"
                                      id={`interviewRounds[${index}].title`}
                                      name={`interviewRounds[${index}].title`}
                                      className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                    />
                                    <ErrorMessage
                                      name={`interviewRounds[${index}].title`}
                                      component="div"
                                      className="text-red-500 text-sm mt-1"
                                    />
                                  </div>

                                  <div>
                                    <label
                                      htmlFor={`interviewRounds[${index}].locationOrMode`}
                                      className="block text-sm font-semibold"
                                    >
                                      Location or Mode
                                    </label>
                                    <Field
                                      type="text"
                                      id={`interviewRounds[${index}].locationOrMode`}
                                      name={`interviewRounds[${index}].locationOrMode`}
                                      className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                    />
                                    <ErrorMessage
                                      name={`interviewRounds[${index}].locationOrMode`}
                                      component="div"
                                      className="text-red-500 text-sm mt-1"
                                    />
                                  </div>

                                  <div>
                                    <label
                                      htmlFor={`interviewRounds[${index}].instructions`}
                                      className="block text-sm font-semibold"
                                    >
                                      Instructions
                                    </label>
                                    <Field
                                      type="text"
                                      id={`interviewRounds[${index}].instructions`}
                                      name={`interviewRounds[${index}].instructions`}
                                      className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                                    />
                                    <ErrorMessage
                                      name={`interviewRounds[${index}].instructions`}
                                      component="div"
                                      className="text-red-500 text-sm mt-1"
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="mt-2 text-red-500"
                                  >
                                    Remove Round
                                  </button>
                                </div>
                              )
                            )}
                            <button
                              type="button"
                              onClick={() =>
                                push({
                                  roundNo:
                                    form.values.interviewRounds?.length + 1,
                                  title: "",
                                  locationOrMode: "",
                                  instructions: "",
                                })
                              }
                              className="bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                            >
                              Add Round
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>

                    <div>
                      <label
                        htmlFor="companyName"
                        className="block text-sm font-semibold"
                      >
                        Company Name
                      </label>
                      <Field
                        type="text"
                        id="companyName"
                        name="companyDetails.companyName"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="companyDetails.companyName"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="companyField"
                        className="block text-sm font-semibold"
                      >
                        Company Field
                      </label>
                      <Field
                        type="text"
                        id="companyField"
                        name="companyDetails.companyField"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="companyDetails.companyField"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="salaryOffering"
                        className="block text-sm font-semibold"
                      >
                        Salary Offering
                      </label>
                      <Field
                        type="text"
                        id="salaryOffering"
                        name="salaryOffering"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="salaryOffering"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="noOfClosings"
                        className="block text-sm font-semibold"
                      >
                        Number of Closings
                      </label>
                      <Field
                        type="number"
                        id="noOfClosings"
                        name="noOfClosings"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="noOfClosings"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="percentage"
                        className="block text-sm font-semibold"
                      >
                        Percentage
                      </label>
                      <Field
                        type="number"
                        id="percentage"
                        name="percentage"
                        className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-blue-500"
                      />
                      <ErrorMessage
                        name="percentage"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <VStack>
                      <label className="block text-sm font-semibold">
                        Working Recruiters
                      </label>
                      <button
                        type="button"
                        onClick={openPopup}
                        className="mt-2 bg-blue-500 text-white font-semibold py-1 px-3 rounded-lg"
                      >
                        Add Recruiters
                      </button>
                      <div>
                        <h3 className="text-lg font-bold mb-2">
                          Selected Recruiters:
                        </h3>
                        <ul className="space-y-2">
                          {selectedRecruiters.map((emp, index) => (
                            <li
                              key={index}
                              className="flex  justify-between items-center p-2 border rounded-lg"
                            >
                              <span>
                                {emp.empId} - {emp.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </VStack>
                  </div>
                </div>

                {showBroughtBy && (
                  <BroughtByListPopUp
                    setSelectedBDIds={setSelectedBDIds}
                    selectedBDIds={selectedBDIds}
                    selectedBDs={selectedBDs}
                    setSelectedBDs={setSelectedBDs}
                  />
                )}

                <button
                  type="submit"
                  className="mt-6 bg-blue-500 text-white cursor-pointer font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
                <EmployeeListPopup
                  isOpen={isPopupOpen}
                  onClose={closePopup}
                  selectedRecruiterIds={selectedRecruiterIds}
                  setSelectedRecruiterIds={setSelectedRecruiterIds}
                  selectedRecruiters={selectedRecruiters}
                  setSelectedRecruiters={setSelectedRecruiters}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default JobDescription;
