import React, { createContext, useContext } from 'react'
import { useEnv } from './EnvContext';
import { useAuth } from './AuthContext';
import { usePipeLine } from './PipelineContext';
import axios from 'axios';

export const InterviewContext = createContext();

const InterviewContextProvider = ({ children }) => {
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const { handleApplicationForm } = usePipeLine()

    const scheduleInterview = async (applicationId, postData) => {
        const token = userData?.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const res = await axios.put(
                `${backendUrl}/application/schedule_interview?applicationId=${applicationId}`,
                postData,
                { headers }

            );
            console.log("scheduleInterview", res.data);
            handleApplicationForm();
        } catch (err) {
            console.log(err)
        }
    }

    const recordInterview = async (applicationId, postData) => {
        const token = userData?.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const res = await axios.put(
                `${backendUrl}/application/record_interview_res?applicationId=${applicationId}`, postData,
                { headers }
            );
            handleApplicationForm();
            console.log("recordCv", res.data);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <InterviewContext.Provider value={{ scheduleInterview, recordInterview }}>
            {children}
        </InterviewContext.Provider>
    )
}

export default InterviewContextProvider

export const useInterview = () => useContext(InterviewContext);