import axios from "axios";
import React, { Children, createContext, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import { sortTransactionsByDate } from "../Utils/ExtraFun";

export const PipelineContext = createContext();

const PipelineContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const [fetchedData, setFetchedData] = useState([]);

  const handleApplicationForm = async () => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingtoast;
    try {
      loadingtoast = toast.loading("fetch application from....", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
      let res = await axios.get(`${backendUrl}/application/all`, { headers });
      const data = sortTransactionsByDate(res?.data?.applications);
      setFetchedData(data);
      console.log("data", data);
      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
    } catch (err) {
      console.log(err);
      console.log(err.response);
      if (err.response.data.message) {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "67px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      }
    } finally {
      if (loadingtoast) {
        toast.dismiss(loadingtoast);
      }
    }
  };

  const forwardCv = async (applicationId) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const confirm = window.confirm("Are you sure you want to forward CV ")
    console.log(confirm)
    if (!confirm) return;
    try {
      const res = await axios.put(
        `${backendUrl}/application/forward_cv?applicationId=${applicationId}`,
        null,
        { headers }
      );
      handleApplicationForm();
      console.log("forwardCv", res.data);

      toast.success(res.data.message, {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const recordCvRes = async (applicationId, postData) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.put(
        `${backendUrl}/application/record_cv_res?applicationId=${applicationId}`, postData,
        { headers }
      );
      handleApplicationForm();
      console.log("recordCv", res.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PipelineContext.Provider
      value={{ handleApplicationForm, fetchedData, forwardCv, recordCvRes }}
    >
      {children}
    </PipelineContext.Provider>
  );
};

export default PipelineContextProvider;

export const usePipeLine = () => useContext(PipelineContext);