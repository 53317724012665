import React, { createContext, useContext, useState } from "react";
import { useEnv } from "./EnvContext";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  const handleSignIn = async (formValues) => {
    console.log("formValues", formValues);
    let loadingToast;
    try {
      loadingToast = toast.loading("SignIn in progress ...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      await axios.post(`${backendUrl}/user/login`, formValues).then((res) => {
        console.log("handleSignInres", res.data.data);
        const data = res.data.data;
        setUserData(data);
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        toast.success(res.data.message, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });

        const role = data.userType;
        console.log("role", role);
        switch (role) {
          case "Admin":
            navigate("/admin/employee");
            break;
          case "HR Manager":
            navigate("/hr/employee");
            break;
          case "Business Developer":
            navigate("/bde/employee");
            break;
          case "Recruiter":
            navigate("/candidate");
            break;
          default:
            toast.error("Role not recognized!");
            break;
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      else
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleSignOut = () => {
    const confirmLogOut = window.confirm("Are you sure you want to logout?");
    if (confirmLogOut) {
      console.log("logout successfull");
      setUserData({});
      navigate("/");
      toast.success("LogOut Successfully");
      localStorage.removeItem("userData");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        handleSignIn,
        userData,
        setUserData,
        handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuth = () => useContext(AuthContext);
