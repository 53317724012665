import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Button,
    ListItem,
    Link,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useCandidate } from "../../../context/CandidateContext";
import { useEnv } from "../../../context/EnvContext";

function CandidateModal({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { candidateData } = useCandidate();
    const { backendUrl } = useEnv();
    useEffect(() => {
        if (candidateData) {
            console.log("candidateData", candidateData);
        }
    }, [candidateData]);

    return (
        <>
            <span onClick={onOpen}>{children}</span>
            <Modal
                isCentered
                isOpen={isOpen}
                x
                onClose={onClose}
                size={"xl"}
                scrollBehavior={"inside"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            <strong>stuId:</strong> {candidateData?.stuId}
                        </Text>
                        <Text>
                            <strong>Name:</strong> {candidateData?.name}
                        </Text>
                        <Text>
                            <strong>Email:</strong> {candidateData?.email}
                        </Text>
                        <Text>
                            <strong>Phone:</strong> {candidateData?.phone}
                        </Text>
                        <Text>
                            <strong>whatsappNo:</strong> {candidateData?.whatsappNo}
                        </Text>
                        <Text>
                            <strong>yearsOfExperience:</strong>{" "}
                            {candidateData?.yearsOfExperience}
                        </Text>
                        <Text>
                            <strong>currentCTC:</strong> {candidateData?.currentCTC}
                        </Text>
                        <Text>
                            <strong>expectedCTC:</strong> {candidateData?.expectedCTC}
                        </Text>
                        <Text>
                            <strong>currentStatus:</strong> {candidateData?.currentStatus}
                        </Text>
                        <Text>
                            <strong>noticePeriod:</strong> {candidateData?.noticePeriod}
                        </Text>
                        <Text>
                            <strong>experienceType:</strong> {candidateData?.experienceType}
                        </Text>

                        <Text>
                            {" "}
                            <strong> Address :</strong>{candidateData?.address?.at},{" "}
                            {candidateData?.address?.city},{candidateData?.address?.po},{" "}
                            {candidateData?.address?.dist}, {candidateData?.address?.state},{" "}
                            {candidateData?.address?.country}, PIN- {candidateData?.address?.pin}
                        </Text>
                        <Text><strong>Previous Company:</strong> Company Name - {candidateData?.previousCompany?.companyName}</Text>
                        <Text>
                            Address :{" "}
                            {candidateData?.previousCompany?.companyAddress?.city}, {candidateData?.previousCompany?.companyAddress?.state},{" "}
                            {candidateData?.previousCompany?.companyAddress?.country}
                        </Text>

                        <Text>
                            <strong>stipend:</strong> {candidateData?.stipend}
                        </Text>
                        <Text>
                            {" "}
                            <strong>skills:</strong>
                            {candidateData?.skills?.map((skills, index) => (
                                <li key={index}>{skills}</li>
                            ))}
                        </Text>
                        <label>Profile :</label>
                        <img
                            width={"45%"}
                            style={{ border: "1px solid" }}
                            src={backendUrl + candidateData?.dp}
                        ></img>
                        <Text>
                            {" "}
                            To show the Cv click {""}
                            <Link
                                href={backendUrl + candidateData?.cv}
                                isExternal
                                rel="noopener noreferrer"
                                color={"blue"}
                            >
                                View CV
                            </Link>
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default CandidateModal;
